.App {
  width: 100%;
  .scene{
    position: fixed;
    height: 100vh;
    width: 333.33vh;
    .background{
      height:100%;
    }
  }
}
