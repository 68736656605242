$dogHeight : 20vh;
$dogHeightStop : 23vh;
.dog-component{
  z-index: 1000;
  position: fixed;
  height: $dogHeight;
  width: $dogHeight;
  object-fit: cover;
  //bottom: 3vh;
  top:97%;
  cursor: pointer;
  &.stop{
    height: $dogHeightStop;
    width: $dogHeightStop;
  }
}
.hello-tooltip{
  z-index: 1000!important;
}